import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { UntilDestroy } from '@shared';
import { ResponseMessagesTypes } from '@app/@shared/models/constants';
import { LoginResponse } from '@app/@shared/models/login-response.model';
import { LoginRequest } from '@app/@shared/models/login-request.model';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { Credentials } from '@app/@shared/models/credentials.model';
import { MessageService } from 'primeng/api';
import { AuthenticationService, CredentialsService } from '..';
import { LoginTypes } from '@app/@shared/enums/login-types.enum';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';
import { SubdomainService } from '@app/@shared/services/subdomain.service';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  fgLogin!: FormGroup;
  isLoading = false;
  subdomainType!: SubdomainTypes | null;
  SubdomainTypes = SubdomainTypes;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private applicationProfileService: ApplicationProfileService,
    private messageService: MessageService,
    private subdomainService: SubdomainService
  ) {}

  ngOnInit() {
    this.subdomainType = this.subdomainService.getDomain();
    this.createForm();
  }

  onLoginClicked(): void {
    // Validate form.
    this.fgLogin.markAllAsTouched();
    if (this.fgLogin.valid) {
      this.isLoading = true;

      const loginRequest: LoginRequest = {
        ...this.fgLogin.value,
        clientAccessIds: [this.credentialsService.credentials?.clientAccessId || ''],
        referenceIdent: '',
        loginType: LoginTypes.Representative,
      };

      const login$ = this.authenticationService.login(loginRequest);

      login$
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.fgLogin.markAsPristine();
          })
        )
        .subscribe({
          next: (loginResponse: LoginResponse) => {
            if (loginResponse.ResponseMessages && loginResponse.ResponseMessages.length > 0) {
              if (loginResponse.ResponseMessages.includes(ResponseMessagesTypes.InvalidUsernamePassword)) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Invalid email address or password.',
                });
                return;
              }
              // Handle other response messages using the enum if needed.
            }

            const credentials: Credentials = {
              clientAccessId: loginResponse.ClientAccessId,
              jwt: loginResponse.JWT,
            };

            // Set in local storage.
            this.credentialsService.setCredentials(credentials);

            this.authenticationService.getApplicationProfile().subscribe({
              next: (applicationProfile: ApplicationProfile) => {
                this.applicationProfileService.setApplicationProfile(applicationProfile);

                this.isLoading = false;
                this.router.navigate([applicationProfile.Representative.CoalitionId, 'dashboard']);
              },
            });
          },
        });
    }
  }

  onForgotPasswordClicked(): void {
    this.router.navigate(['auth/reset-password']);
  }

  private createForm() {
    this.fgLogin = this.formBuilder.group({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      remember: new FormControl(null),
    });
  }
}
