import { Component, OnInit } from '@angular/core';
import { Billing } from '@app/@shared/models/billing.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  coalitionId!: string | null;
  billings!: Billing[];
  isLoading: boolean = true;

  rows: number = 10;
  pageNumber: number = 1;
  totalRecords: number = 0;

  constructor() {}

  ngOnInit(): void {}

  // loadBillings(event?: any): void {
  //   if (this.coalitionId) {
  //     // Calculate the pageNumber based on the first value
  //     this.pageNumber = Math.floor(event.first / this.rows) + 1;

  //     this.billingService
  //       .getBilling(
  //         this.coalitionId,
  //         '',
  //         event?.filters?.StartDate?.value ?? '',
  //         event?.filters?.EndDate?.value ?? '',
  //         this.pageNumber,
  //         event.rows
  //       )
  //       .pipe(untilDestroyed(this))
  //       .subscribe((response: ApiResponse<Billing>) => {
  //         this.billings = response.Collection;
  //         this.totalRecords = response.TotalRecordCount;
  //         this.isLoading = false;
  //       });
  //   }
  // }
}
