import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IframeEventTypes } from '@app/@shared/enums/iframe-event-types.enum';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { IframeManagerService } from '@app/@shared/services/iframe-manager.service';
import { AuthenticationService } from '@app/auth';
import { environment } from '@env/environment';
import { ConfigurationService } from '@ep/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<void>();

  items!: MenuItem[];
  applicationProfile!: ApplicationProfile;
  fullUserName!: string;
  isMobileScreen: boolean = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private applicationProfileService: ApplicationProfileService,
    private iframeManagerService: IframeManagerService,
    private configurationService: ConfigurationService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobileScreen = window.innerWidth <= 425;
  }

  ngOnInit() {
    this.iframeManagerService
      .onMessageReceived()
      .pipe(
        untilDestroyed(this),
        filter(
          (event) =>
            event.origin ===
              (this.configurationService.coalitionPortalUrlLegacy ?? environment.coalitionPortalUrlLegacy) &&
            event.data === IframeEventTypes.LegacyAppTokenExpired
        )
      )
      .subscribe(() => {
        this.onLogoutClicked();
      });

    this.onResize({});

    this.items = [
      {
        label: 'Change Password',
        icon: 'pi pi-user-edit',
        command: () => {
          this.onChangePasswordClicked();
        },
      },
      {
        label: 'Log Out',
        icon: 'pi pi-sign-out',
        command: () => {
          this.onLogoutClicked();
        },
      },
    ];

    this.applicationProfileService.applicationProfile$
      .pipe(untilDestroyed(this))
      .subscribe((applicationProfile: ApplicationProfile) => {
        this.applicationProfile = applicationProfile;
        this.fullUserName = `${this.applicationProfile.Representative.NameFirst} ${this.applicationProfile.Representative.NameLast}`;
      });
  }

  onLogoutClicked(): void {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/auth/login'], { replaceUrl: true }));
  }

  onChangePasswordClicked(): void {
    this.iframeManagerService.setShouldShowIframe(false);
    this.router.navigate([`${this.applicationProfile.Representative.CoalitionId}/change-password`]);
  }

  onCloseClicked(event?: any): void {
    this.closeClicked.emit(event);
  }
}
