import { inject, Injectable } from '@angular/core';
import { MerchantService } from '@app/resources/services/merchant.service';
import { Observable } from 'rxjs';
import { Merchant } from '@shared/models/merchant.model';
import { map } from 'rxjs/operators';
import { CreateEnrollmentRequest, EnrollmentService } from '@app/resources/services/enrollment.service';
import { EmailService, SendContactCustomerSupportEmailRequest } from '@app/resources/services/email.service';

@Injectable()
export class MerchantsApiService {
  private readonly merchantService = inject(MerchantService);
  private readonly enrollmentService = inject(EnrollmentService);
  private readonly emailService = inject(EmailService);

  getMerchants(coalitionId: number): Observable<Merchant[]> {
    return this.merchantService.findAll(coalitionId).pipe(
      map((result: any) => {
        if (result !== null && result.$values !== null) {
          return result.$values.map(mapToMerchant);
        }
        return [];
      })
    );
  }

  enrollMerchant(createEnrollmentRequest: CreateEnrollmentRequest) {
    return this.enrollmentService.create(createEnrollmentRequest);
  }

  sendContactSupportEmail(sendContactCustomerSupportRequest: SendContactCustomerSupportEmailRequest) {
    return this.emailService.sendContactCustomerSupport(sendContactCustomerSupportRequest);
  }
}

function mapToMerchant(data: any): Merchant {
  return {
    MerchantId: data.merchantId,
    Name: data.name,
  };
}
