import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginTypes } from '@app/@shared/enums/login-types.enum';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';
import { ApiResponse } from '@app/@shared/models/api-response.model';
import { ChangePassword } from '@app/@shared/models/change-password.model';
import { Representative } from '@app/@shared/models/representative.model';
import { AuthenticationService } from '@app/@shared/services/authentication.service';
import { SubdomainService } from '@app/@shared/services/subdomain.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-confirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.scss'],
})
export class ConfirmResetPasswordComponent implements OnInit {
  fgChangePassword!: FormGroup;
  passwordResetId!: string | null;
  representative!: Representative;
  isLoading: boolean = false;
  isLinkExpired: boolean = false;
  subdomainType!: SubdomainTypes | null;
  SubdomainTypes = SubdomainTypes;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private subdomainService: SubdomainService
  ) {}

  ngOnInit(): void {
    this.subdomainType = this.subdomainService.getDomain();
    this.passwordResetId = this.route.snapshot.paramMap.get('passwordResetId');

    // Need to check if password reset is expired.
    if (this.passwordResetId) {
      this.authenticationService
        .getRepresentativeByPasswordResetId(this.passwordResetId)
        .pipe(
          untilDestroyed(this),
          catchError((error) => {
            this.isLoading = false;
            this.isLinkExpired = true;
            return throwError(error);
          })
        )
        .subscribe((response: ApiResponse<Representative>) => {
          this.isLoading = false;
          if (!response.Collection.length) {
            this.isLinkExpired = true;
          } else {
            this.representative = response.Collection[0];
          }
        });

      this.createForm();
    }
  }

  onChangePasswordClicked(): void {
    this.fgChangePassword.markAllAsTouched();
    if (this.fgChangePassword.valid) {
      this.isLoading = true;

      const changePassword: ChangePassword = {
        ...this.fgChangePassword.value,
        $id: this.representative.$id,
        loginType: LoginTypes.Representative,
        passwordResetId: this.passwordResetId,
        representativeId: this.representative.RepresentativeId,
      };

      this.authenticationService
        .confirmPasswordReset(changePassword)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.fgChangePassword.reset();
          this.fgChangePassword.updateValueAndValidity();
          this.messageService.add({ severity: 'success', summary: 'Password Change Successful!' });
          this.isLoading = false;
          this.router.navigate(['auth/login']);
        });
    }
  }

  onReturnToLoginClicked(): void {
    this.router.navigate(['auth/login']);
  }

  onResetPasswordClicked(): void {
    this.router.navigate(['auth/reset-password']);
  }

  passwordsMatchValidator(control: AbstractControl): { [key: string]: any } | null {
    const newPasswordA = control.get('NewPasswordA');
    const newPasswordB = control.get('NewPasswordB');

    if (newPasswordA && newPasswordB && newPasswordA.value !== newPasswordB.value) {
      return { passwordsNotMatch: true };
    }

    return null;
  }

  private createForm() {
    this.fgChangePassword = this.formBuilder.group(
      {
        NewPasswordA: new FormControl('', [Validators.required, Validators.minLength(8)]),
        NewPasswordB: new FormControl('', Validators.required),
      },
      { validators: this.passwordsMatchValidator }
    );
  }
}
