import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { KeyFilterModule } from 'primeng/keyfilter';
import { concat, defer, of, Subscription } from 'rxjs';
import { isPrecise } from '@ep/shared';

function isTenthPennyPrecise(num: number | string) {
  const numString = typeof num === 'number' ? String(num) : num;
  if (!numString.includes('.')) return true;

  const decimalPrecision = numString.split('.')[1]?.length;
  return 0 < decimalPrecision && decimalPrecision <= 3;
}

@Component({
  selector: 'app-merchant-sms-price-control',
  templateUrl: './merchant-sms-price-control.component.html',
  styleUrls: ['./merchant-sms-price-control.component.scss'],
  standalone: true,
  imports: [InputTextModule, ReactiveFormsModule, NgIf, KeyFilterModule, NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MerchantSmsPriceControlComponent,
      multi: true,
    },
  ],
})
export class MerchantSmsPriceControlComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() label = '';
  protected price = new FormControl<string | null>(null, {
    validators: [Validators.required, Validators.min(0.0), Validators.pattern('\\d*\\.?\\d{1,3}')],
  });

  protected value: number | null = null;
  private isFocused = false;
  private subs = new Subscription();

  onChange: any = (value: Date) => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    this.subs.add(
      concat(
        defer(() => of(this.price.value)),
        this.price.valueChanges
      ).subscribe((price) => {
        if (price !== null && price !== '' && isTenthPennyPrecise(price)) {
          this.value = parseFloat(price);
        } else this.value = null;
        this.onChange(this.value);
        this.onTouch();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  protected onFocusIn() {
    if (!this.isFocused) {
      this.isFocused = true;
    }
  }

  protected onFocusOut() {
    this.isFocused = false;
  }

  protected get isFocusedOut() {
    return !this.isFocused;
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.price.disable();
    } else {
      this.price.enable();
    }
  }

  setValue(value?: number | null) {
    this.value = value ?? null;
  }

  writeValue(value?: number | null): void {
    this.setValue(value);
    this.price.setValue(!value && value !== 0 ? null : `${value}`, {
      emitEvent: false,
    });
  }

  protected getErrorMessage() {
    if (this.price.errors) {
      if (this.price.errors['min']) {
        return 'Must enter a value of at least 0';
      } else if (this.price.errors['pattern']) {
        return 'Must enter a price of up to a tenth of a penny value (e.g. 100.1, 0.016, etc.)';
      }
    }
    return 'Must enter a value of at least 0';
  }
}
