<div id="merchant-form-wrapper">
  <form [formGroup]="merchantForm" id="merchant-form" data-cy="merchant-form">
    <div class="full-width merchant-form-section">
      <ep-email-control class="full-height-width" formControlName="Email" data-cy="merchant-email"></ep-email-control>
      <app-merchant-payment-type-control
        class="full-height-width"
        formControlName="PaymentType"
        data-cy="merchant-payment-type"
      ></app-merchant-payment-type-control>
    </div>
    <div class="full-width merchant-form-section">
      <ep-price-control
        class="full-height-width"
        formControlName="BasePrice"
        label="Base Price"
        data-cy="merchant-base-price"
      ></ep-price-control>
      <ep-price-control
        class="full-height-width"
        formControlName="PricePerLocation"
        label="Price Per Location"
        data-cy="merchant-location-price"
      ></ep-price-control>
    </div>
    <div class="full-width merchant-form-section">
      <ep-price-control
        class="full-height-width"
        formControlName="PricePerDevice"
        label="Price Per Device"
        data-cy="merchant-device-price"
      ></ep-price-control>
      <app-merchant-sms-price-control
        class="full-height-width"
        formControlName="PricePerSms"
        label="Price Per Sms"
        data-cy="merchant-sms-price"
      ></app-merchant-sms-price-control>
    </div>
    <div class="full-width merchant-form-section">
      <ep-positive-integer-control
        class="full-height-width"
        formControlName="PrepaidCampaign"
        label="Prepaid Campaign"
        data-cy="merchant-prepaid-campaign-price"
      ></ep-positive-integer-control>
      <ep-price-control
        class="full-height-width"
        formControlName="PricePerCampaign"
        label="Price Per Campaign"
        data-cy="merchant-campaign-price"
      ></ep-price-control>
    </div>
  </form>
</div>
