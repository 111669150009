import { Component, inject, OnInit } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { MerchantFormService } from '@app/areas/merchants/services/merchant-form.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MerchantPaymentTypeControlComponent } from '@app/areas/merchants/controls/merchant-payment-type-control/merchant-payment-type-control.component';
import { PositiveIntegerControlComponent, PriceControlComponent, EmailControlComponent } from '@ep/shared';
import { MerchantSmsPriceControlComponent } from '@app/areas/merchants/controls/merchant-sms-price-control/merchant-sms-price-control.component';

@Component({
  selector: 'app-merchant-form',
  templateUrl: './merchant-form.component.html',
  styleUrls: ['./merchant-form.component.scss'],
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    MerchantPaymentTypeControlComponent,
    PriceControlComponent,
    ReactiveFormsModule,
    PositiveIntegerControlComponent,
    EmailControlComponent,
    MerchantSmsPriceControlComponent,
  ],
})
export class MerchantFormComponent {
  private readonly merchantFormService = inject(MerchantFormService);
  protected merchantForm = this.merchantFormService.getForm();
}
