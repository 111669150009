import { Component, inject } from '@angular/core';
import { ConfigurationService, EpTableComponent, EPTableConfig } from '@ep/shared';
import { Merchant } from '@shared/models/merchant.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { IframeManagerService } from '@shared/services/iframe-manager.service';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import { environment } from '@env/environment.staging';
import { MerchantsApiService } from '@app/areas/merchants/services/merchants.api.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-merchant-table',
  templateUrl: './merchant-table.component.html',
  styleUrls: ['./merchant-table.component.scss'],
  standalone: true,
  imports: [EpTableComponent, AsyncPipe],
})
export class MerchantTableComponent {
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly configurationService = inject(ConfigurationService);
  private readonly iframeManagerService = inject(IframeManagerService);
  private readonly merchantApiService = inject(MerchantsApiService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  protected config: EPTableConfig<Merchant> = {
    header: 'merchants',
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'Name',
        field: 'Name',
        columnType: 'string',
        defaultValue: 'Missing Name',
      },
    ],
    style: {
      'min-width': '50rem',
      border: '5px',
    },
    globalFilterFields: ['Name'],
    actions: {
      addNew: () => {
        this.router.navigate(['new'], { relativeTo: this.route });
      },
      rowClick: (merchant: Merchant) => {
        const iframeSrc = this.iframeManagerService.generateIframeSrc(
          this.configurationService.coalitionPortalUrlLegacy ?? environment.coalitionPortalUrlLegacy,
          this.applicationProfileService.getApplicationProfile().Representative.CoalitionId,
          'merchants',
          `${merchant.MerchantId}`
        );
        if (iframeSrc) {
          this.iframeManagerService.setIframeSrc(iframeSrc);
          this.iframeManagerService.setShouldShowIframe(true);
        }
      },
    },
  };

  protected merchants$: Observable<Merchant[]> = this.applicationProfileService.applicationProfile$.pipe(
    switchMap((appProfile) => {
      const coalitionId = appProfile.Representative.CoalitionId;
      return this.merchantApiService.getMerchants(coalitionId);
    })
  );
}
