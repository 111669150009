<div>
  <span>
    <label class="paymentType-label"> Payment Type </label>
    <p-dropdown
      [formControl]="paymentType"
      [options]="paymentTypes"
      optionLabel="name"
      data-cy="merchant-payment-type-control"
    ></p-dropdown>
  </span>
</div>
