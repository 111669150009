import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoyaltyProgramRoutingModule } from './loyalty-program-routing.module';
import { LoyaltyProgramComponent } from './loyalty-program.component';
import { SharedModule } from '@app/@shared';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [LoyaltyProgramComponent],
  imports: [CommonModule, LoyaltyProgramRoutingModule, SharedModule, TableModule, ButtonModule],
})
export class LoyaltyProgramModule {}
