import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillingRoutingModule } from './billing-routing.module';
import { BillingComponent } from './billing.component';
import { SharedModule } from '@app/@shared';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

@NgModule({
  declarations: [BillingComponent],
  imports: [CommonModule, BillingRoutingModule, SharedModule, TableModule, ButtonModule],
})
export class BillingModule {}
