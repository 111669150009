<!-- <p-table
  [value]="representatives"
  [lazy]="true"
  (onLazyLoad)="loadRepresentatives($event)"
  dataKey="id"
  [tableStyle]="{ 'min-width': '50rem' }"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  selectionMode="single"
>
  <ng-template pTemplate="caption">
    <div class="table-header row justify-content-between align-items-center">
      <div class="col-auto"><h4 class="text-uppercase">representatives</h4></div>
      <div class="col-auto"><p-button label="New Representative" styleClass="p-button-secondary"></p-button></div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="filter-cell">
        <div class="filter-content">
          <p-columnFilter type="text" field="NameLast" placeholder="Search by last name..."></p-columnFilter
          ><i class="pi pi-search"></i>
        </div>
      </th>
      <th class="filter-cell">
        <div class="filter-content">
          <p-columnFilter type="text" field="NameFirst" placeholder="Search by first name..."></p-columnFilter
          ><i class="pi pi-search"></i>
        </div>
      </th>
    </tr>
    <tr>
      <th class="text-uppercase">last name</th>
      <th class="text-uppercase">first name</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-representative>
    <tr>
      <td>{{ representative.NameLast }}</td>
      <td>{{ representative.NameFirst }}</td>
    </tr>
  </ng-template>
</p-table> -->
