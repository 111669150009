import { Component, OnInit } from '@angular/core';
import { Representative } from '@app/@shared/models/representative.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-loyalty-program',
  templateUrl: './loyalty-program.component.html',
  styleUrls: ['./loyalty-program.component.scss'],
})
export class LoyaltyProgramComponent implements OnInit {
  coalitionId!: string | null;
  representatives: Representative[] = [];
  isLoading: boolean = true;

  rows: number = 10;
  pageNumber: number = 1;
  totalRecords: number = 0;

  constructor() {}

  ngOnInit(): void {}
}
