import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MerchantService extends BaseApiService {
  private readonly merchantEndpoint = `/v2/Merchants`;
  findAll(coalitionId: number) {
    return this.get(`${this.merchantEndpoint}`, {
      coalitionId: coalitionId,
    }).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }
}
