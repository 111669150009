import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MerchantsRoutingModule } from './merchants-routing.module';
import { MerchantsComponent } from './merchants.component';
import { SharedModule } from '@app/@shared';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { MerchantTableComponent } from './components/merchant-table/merchant-table.component';
import { MerchantsApiService } from '@app/areas/merchants/services/merchants.api.service';
import { MerchantFormComponent } from './forms/merchant-form/merchant-form.component';
import { MerchantFormService } from '@app/areas/merchants/services/merchant-form.service';
import { CardModule } from 'primeng/card';
import { NewMerchantComponent } from './components/new-merchant/new-merchant.component';
import { MerchantPaymentTypeControlComponent } from './controls/merchant-payment-type-control/merchant-payment-type-control.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MerchantEnrollmentConfirmationComponent } from './components/merchant-enrollment-confirmation/merchant-enrollment-confirmation.component';
import { MerchantSmsPriceControlComponent } from './controls/merchant-sms-price-control/merchant-sms-price-control.component';

@NgModule({
  declarations: [MerchantsComponent],
  imports: [
    CommonModule,
    MerchantsRoutingModule,
    SharedModule,
    TableModule,
    ButtonModule,
    MerchantTableComponent,
    MerchantFormComponent,
    NewMerchantComponent,
    MerchantPaymentTypeControlComponent,
    MerchantEnrollmentConfirmationComponent,
    MerchantSmsPriceControlComponent,
    CardModule,
    FormsModule,
    DropdownModule,
  ],
  providers: [MerchantsApiService, MerchantFormService],
})
export class MerchantsModule {}
