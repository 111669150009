import { Component, OnInit } from '@angular/core';
import { Consumer } from '@app/@shared/models/consumer.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent implements OnInit {
  coalitionId!: string | null;
  consumers!: Consumer[];
  isLoading: boolean = true;

  rows: number = 10;
  pageNumber: number = 1;
  totalRecords: number = 0;

  constructor() {}

  ngOnInit(): void {}
}
