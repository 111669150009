<div id="sms-price-control" class="full-height-width">
  <span class="full-height-width">
    <label class="sms-price-label">{{ label }}</label>
    <div class="p-inputgroup full-height-width">
      <span class="p-inputgroup-addon">$</span>
      <input
        pInputText
        pKeyFilter="money"
        class="sms-price-control full-height-width"
        [formControl]="price"
        data-cy="sms-price-control"
        (focusout)="onFocusOut()"
        (input)="onFocusIn()"
        [ngClass]="{
          'ng-pending': !isFocusedOut
        }"
      />
    </div>
  </span>
  <p
    *ngIf="price.dirty && price.errors && isFocusedOut"
    class="sms-price-control-error"
    data-cy="sms-price-control-error"
  >
    <i class="fa-solid fa-triangle-exclamation" style="color: #ff0000"></i>
    {{ getErrorMessage() }}
  </p>
</div>
