import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MerchantPaymentTypes } from '@shared/enums';
import { DropdownModule } from 'primeng/dropdown';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

type PaymentTypeControlValue = {
  name: string;
  value: MerchantPaymentTypes;
};

@Component({
  selector: 'app-merchant-payment-type-control',
  templateUrl: './merchant-payment-type-control.component.html',
  styleUrls: ['./merchant-payment-type-control.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, DropdownModule, KeyFilterModule, AsyncPipe, NgClass, NgIf],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MerchantPaymentTypeControlComponent,
      multi: true,
    },
  ],
})
export class MerchantPaymentTypeControlComponent implements ControlValueAccessor {
  protected paymentType = new FormControl<PaymentTypeControlValue>(
    {
      disabled: true,
      value: {
        name: 'Paid',
        value: MerchantPaymentTypes.Paid,
      },
    },
    { validators: [Validators.required], nonNullable: true }
  );
  protected paymentTypes = [
    {
      name: 'Paid',
      value: MerchantPaymentTypes.Paid,
    },
  ];
  onChange: any = (value: string) => {};
  onTouch: any = () => {};

  value: MerchantPaymentTypes = MerchantPaymentTypes.Paid;

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.paymentType.disable({ emitEvent: false }) : this.paymentType.enable({ emitEvent: false });
  }

  setValue(value: MerchantPaymentTypes) {
    this.value = value;
  }

  writeValue(value: MerchantPaymentTypes): void {
    this.setValue(value);
    this.paymentType.setValue(this.getPaymentTypeValue(value), { emitEvent: false });
  }

  private getPaymentTypeValue(value: MerchantPaymentTypes) {
    return {
      name: 'Paid',
      value: MerchantPaymentTypes.Paid,
    };
  }
}
