<ep-auth-container
  [imageSrc]="
    subdomainType === SubdomainTypes.EagleProcessing
      ? '../../assets/eagle-logo.png'
      : '../../assets/enroll-and-pay-logo.png'
  "
>
  <div class="mb-4">
    <h5 class="fw-semibold">Login with your coalition account</h5>
  </div>

  <form [formGroup]="fgLogin" (keyup.enter)="onLoginClicked()">
    <div class="form-group mb-4">
      <div class="flex flex-column gap-2">
        <label class="fw-semibold" htmlFor="username">Email:</label>
        <input
          type="email"
          pInputText
          formControlName="username"
          autocomplete="on"
          [class.is-invalid]="
            fgLogin.get('username')?.invalid && (fgLogin.get('username')?.dirty || fgLogin.get('username')?.touched)
          "
          data-cy="coalition-login-email"
        />
        <div class="invalid-feedback" *ngIf="fgLogin.get('username')?.errors?.['required']">Email is required</div>
        <div class="invalid-feedback" *ngIf="fgLogin.get('username')?.errors?.['email']">Email is invalid</div>
      </div>
    </div>
    <div class="form-group mb-4">
      <div class="flex flex-column gap-2">
        <label class="fw-semibold" htmlFor="password">Password:</label>
        <p-password
          formControlName="password"
          [toggleMask]="true"
          [feedback]="false"
          autocomplete="on"
          [class.is-invalid]="
            fgLogin.get('password')?.invalid && (fgLogin.get('password')?.dirty || fgLogin.get('password')?.touched)
          "
          data-cy="coalition-login-password"
        ></p-password>
        <div class="invalid-feedback" *ngIf="fgLogin.get('password')?.errors?.['required']">Password is required</div>
      </div>
    </div>
    <div class="mb-4">
      <p-button
        label="LOGIN"
        [loading]="isLoading"
        (onClick)="onLoginClicked()"
        data-cy="coalition-login-button"
      ></p-button>
    </div>
    <div>Forgot Password? <a (click)="onForgotPasswordClicked()" routerLinkActive="active">Click here to reset</a></div>
  </form>
</ep-auth-container>
