import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  applicationProfile!: ApplicationProfile;
  coalitionId!: string | null;
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private applicationProfileService: ApplicationProfileService
  ) {}

  ngOnInit(): void {
    this.handleRedirect();
  }

  handleRedirect(): void {
    this.applicationProfileService.applicationProfile$
      .pipe(untilDestroyed(this))
      .subscribe((applicationProfile: ApplicationProfile) => {
        this.applicationProfile = applicationProfile;
        this.router.navigate([`${this.applicationProfile.Representative.CoalitionId}/dashboard`]);
      });
  }
}
