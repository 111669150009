import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';

export interface SendContactCustomerSupportEmailRequest {
  RepresentativeId: number;
  Body: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailService extends BaseApiService {
  private readonly emailEndpoint = `/v2/Emails`;
  sendContactCustomerSupport(request: SendContactCustomerSupportEmailRequest) {
    return this.post(`${this.emailEndpoint}/contact-customer-support`, request).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }
}
