import { Component, inject, Input, OnInit } from '@angular/core';
import { CardModule } from 'primeng/card';
import { AsyncPipe } from '@angular/common';
import { ApplicationProfileService } from '@shared/services/application-profile.service';

@Component({
  selector: 'app-merchant-enrollment-confirmation',
  templateUrl: './merchant-enrollment-confirmation.component.html',
  styleUrls: ['./merchant-enrollment-confirmation.component.scss'],
  standalone: true,
  imports: [CardModule],
})
export class MerchantEnrollmentConfirmationComponent {
  @Input() merchantEnrollmentLink = '';
}
