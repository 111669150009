<div id="new-merchant-wrapper" data-cy="new-merchant-wrapper">
  <div id="go-back-merchants-wrapper">
    <p-button
      (onClick)="goBackToMerchantsTable()"
      [style]="{ height: '100%' }"
      id="go-back-new-merchant"
      data-cy="go-back-merchant-button"
    >
      <i class="fa-solid fa-arrow-left fa-xl" style="color: #213246"></i>
    </p-button>
  </div>
  <p-card
    *ngIf="merchantEnrollmentLink === null; else enrollmentVerification"
    class="merchant-form-card"
    data-cy="merchant-form-card"
  >
    <ng-template pTemplate="header">
      <div id="merchant-form-card-header">
        <h1>{{ 'New Merchant' }}</h1>
      </div>
    </ng-template>
    <app-merchant-form></app-merchant-form>
    <ng-template pTemplate="footer">
      <div id="merchant-form-card-footer">
        <p-button
          (onClick)="saveMerchant()"
          [style]="{ height: '100%' }"
          [disabled]="(isSaveDisabled$ | async) === true"
          [loading]="isSaving"
          size="large"
          id="save-new-merchant"
          data-cy="save-merchant-button"
        >
          Save
        </p-button>
      </div>
    </ng-template>
  </p-card>
  <ng-template #enrollmentVerification>
    <app-merchant-enrollment-confirmation
      [merchantEnrollmentLink]="merchantEnrollmentLink!"
      style="width: 50%; align-self: center"
      id="merchant-enrollment-confirmation"
    ></app-merchant-enrollment-confirmation>
  </ng-template>
</div>
