import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from '..';
import { ResetPasswordRequest } from '@app/@shared/models/reset-password-request.model';
import { Router } from '@angular/router';
import { LoginTypes } from '@app/@shared/enums/login-types.enum';
import { SubdomainService } from '@app/@shared/services/subdomain.service';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  fgForgotPassword!: FormGroup;
  shouldShowReturn: boolean = false;
  isLoading = false;
  subdomainType!: SubdomainTypes | null;
  SubdomainTypes = SubdomainTypes;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private subdomainService: SubdomainService
  ) {}

  ngOnInit(): void {
    this.subdomainType = this.subdomainService.getDomain();
    this.createForm();
  }

  onResetPasswordClicked(): void {
    // Validate form.
    this.fgForgotPassword.markAllAsTouched();
    if (this.fgForgotPassword.valid) {
      this.isLoading = true;

      const forgotPasswordRequest: ResetPasswordRequest = {
        emailAddress: this.fgForgotPassword.controls['email'].value,
        loginType: LoginTypes.Representative,
      };

      this.authenticationService
        .forgotPassword(forgotPasswordRequest)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.shouldShowReturn = true;
          this.isLoading = false;
        });
    }
  }

  onCancelClicked(): void {
    this.router.navigate(['auth/login']);
  }

  private createForm() {
    this.fgForgotPassword = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }
}
