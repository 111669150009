import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApplicationProfileService } from '../services/application-profile.service';
import { ApplicationProfile } from '../models/application-profile.model';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class GetApplicationProfileResolver implements Resolve<ApplicationProfile | null> {
  constructor(
    private authenticationService: AuthenticationService,
    private applicationProfileService: ApplicationProfileService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ApplicationProfile | null> {
    const shouldResolve = !this.applicationProfileService.getApplicationProfile();

    if (shouldResolve) {
      return this.authenticationService.getApplicationProfile().pipe(
        map((applicationProfile: ApplicationProfile) => {
          if (!applicationProfile.Representative) {
            this.router.navigate(['/auth/login']);
          }
          this.applicationProfileService.setApplicationProfile(applicationProfile);
          return applicationProfile;
        }),
        catchError((error) => {
          // Navigate to '/auth/login' on error
          this.router.navigate(['/auth/login']);
          // Re-throw the error as an observable error
          return throwError(error);
        })
      );
    } else {
      // If condition is false, return null without making the request
      return of(null);
    }
  }
}
