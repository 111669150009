<!-- <p-table
  [value]="billings"
  [lazy]="true"
  (onLazyLoad)="loadBillings($event)"
  dataKey="id"
  [tableStyle]="{ 'min-width': '50rem' }"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  selectionMode="single"
>
  <ng-template pTemplate="caption">
    <div class="table-header row justify-content-between align-items-center">
      <div class="col-auto"><h4 class="text-uppercase">billing cycles</h4></div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="text-uppercase">start date</th>
      <th class="text-uppercase">end date</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-billing>
    <tr>
      <td>{{ billing.StartDate | date : 'MM-dd-yyyy' }}</td>
      <td>{{ billing.EndDate | date : 'MM-dd-yyyy' }}</td>
    </tr>
  </ng-template>
</p-table> -->
