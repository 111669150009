import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';
import { MerchantPaymentTypes } from '@shared/enums';

export interface CreateEnrollmentPricingInfo {
  Base?: number;
  PerCampaign?: number;
  PerDevice?: number;
  PerLocation?: number;
  PerSMS?: number;
  MerchantPaymentType?: MerchantPaymentTypes;
  MaxFreeEnrollments?: number;
  PrepaidCampaigns?: number;
}

export interface CreateEnrollmentRequest {
  CoalitionId: number;
  SalesRepresentativeId: number;
  MerchantEmail: string;
  PricingInformation: CreateEnrollmentPricingInfo;
}

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService extends BaseApiService {
  private readonly enrollmentEndpoint = `/v2/Enrollments`;
  create(createEnrollmentRequest: CreateEnrollmentRequest) {
    return this.post(`${this.enrollmentEndpoint}`, createEnrollmentRequest).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }
}
