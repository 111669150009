<p-card class="merchant-enrollment-verification-card" data-cy="enrollment-verification-card">
  <ng-template pTemplate="header">
    <div id="merchant-enrollment-verification-card-header">
      <h1 id="enrollment-confirmation-title">{{ 'Enrollment Confirmation' }}</h1>
      <i class="fa-solid fa-circle-check fa-2xl" style="color: #1cd431"></i>
    </div>
  </ng-template>
  <div id="enrollment-verification">
    <p>
      An enrollment opportunity has been created. An email has been sent with a link to continue the onboarding process.
      The link is also provided below for your convenience. When the merchant has completed the enrollment process, you
      will receive a verification email.
    </p>

    <a [href]="merchantEnrollmentLink" target="_blank" data-cy="enrollment-verification-link">{{
      merchantEnrollmentLink
    }}</a>
  </div>
</p-card>
