import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { MerchantsComponent } from './merchants.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AccessGuard } from '@app/@shared/guards/access.guard';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { NewMerchantComponent } from '@app/areas/merchants/components/new-merchant/new-merchant.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':coalitionId/merchants',
      component: MerchantsComponent,
      canActivate: [AccessGuard],
      data: { title: marker('Merchants'), requiredAccessTypes: [AccessType.MerchantAdmin] },
    },
    {
      path: ':coalitionId/merchants/new',
      component: NewMerchantComponent,
      canActivate: [AccessGuard],
      data: { title: marker('New Merchant'), requiredAccessTypes: [AccessType.MerchantAdmin] },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MerchantsRoutingModule {}
